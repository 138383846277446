<script>
import {defineComponent} from 'vue'
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";

export default defineComponent({
  name: "Autocomplete",
  data() {
    return {
      selected_application: null,
      application_options: []
    }
  },
  components: {
    Multiselect
  },
  methods: {
    async searchApplications(query) {
      if (query.trim()) {
        try {
          let response = await axios.get(`/code/application/list/numbers/${query.trim()}/`)
          this.application_options = response.data.map(application => {
            let forwarder_id = application[2]
            let forwarder_name = application[1]
            let application_number = application[0]

            return {
              value: forwarder_id + '-' + application_number,
              label: forwarder_name + ' - ' + application_number,
              forwarder_id: forwarder_id,
              forwarder_name: forwarder_name,
              application_number: application_number
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    onApplicationSelected(event) {
      if (event) {
        let forwarder_id = event.forwarder_id
        let application_number = event.application_number

        this.$emit('applicationSelected', {
          forwarder_id: forwarder_id,
          application_number: application_number
        })
      }
    }
  }
})
</script>

<template>
  <b-row>
    <b-col sm="12" class="px-0">
      <b-card no-body>
        <b-card-body>
          <h4 class="mb-3">
            <i class="mdi mdi-star-check h1 align-middle me-1 mb-0 text-warning"></i>
            AutoComplete
          </h4>
          <p class="fs-16 text-muted">
            Start typing anything you know about application to find it and let
            us do the rest
          </p>

          <div>
            <label class="form-label">Search applications</label>
            <Multiselect
                v-model="selected_application"
                :options="application_options"
                @search-change="searchApplications"
                @input="onApplicationSelected"
                class="fs-24" :searchable="true"
                placeholder="Start typing ..."
                :object="true"
            >
            </Multiselect>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>